import React, { Component } from "react"
import { connect } from "react-redux"
import GoTrue from "gotrue-js"
import { navigate } from "gatsby"
import get from "lodash/get"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import PageHero from "../components/userPanel"
import Icon from "react-icons-kit"
import Container from "./../components/container"
import { sun } from "react-icons-kit/icomoon/sun"
import { office } from "react-icons-kit/icomoon/office"
import { ic_assignment_turned_in } from "react-icons-kit/md/ic_assignment_turned_in"
import { feather } from "react-icons-kit/feather/feather"
import { ic_verified_user } from "react-icons-kit/md/ic_verified_user"
import { ic_done_all } from "react-icons-kit/md/ic_done_all"
import { window, document } from "browser-monads"
import { calendar } from "react-icons-kit/icomoon/calendar"
import { wpforms } from "react-icons-kit/fa/wpforms"
import { clipboard } from "react-icons-kit/fa/clipboard"
import { fileTextO } from "react-icons-kit/fa/fileTextO"
import { money } from "react-icons-kit/fa/money"
import { envelopeO } from "react-icons-kit/fa/envelopeO"
import { user } from "react-icons-kit/metrize/user"
import { cloud } from "react-icons-kit/iconic/cloud"
import { users } from "react-icons-kit/fa/users"
import { Loader } from "./../components/dashboard/loading"
import Popup from "reactjs-popup"
import { trash } from "react-icons-kit/fa/trash"
import { send } from "react-icons-kit/fa/send"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChild, faPen } from "@fortawesome/free-solid-svg-icons"
import "./../components/dashboard/dashboard.css"
import styles from "./../components/RegisterForm/registerForm.module.css"

import { toggleAdminDeleteTeamLoad } from "./../actions/loaders/toggleAdminDeleteTeamLoad"
import { toggleEmailSendingLoad } from "./../actions/loaders/toggleEmailSendingLoad"
import { toggleApprovalLoad } from "./../actions/loaders/toggleApprovalLoad"
import { toggleDeclineLoad } from "./../actions/loaders/toggleDeclineLoad"
import { toggleAdminGeneralEditLoader } from "./../actions/loaders/toggleAdminGeneralEditLoader"
import { toggleSingleMemberAdminEditLoader } from "./../actions/loaders/toggleSingleMemberAdminEditLoader"
import { adminQueryForSlots, adminQueryForTeams } from "../controllers/admin"

const NETLIFY_FUNC = "farajaraftingchallenge.org/.netlify/functions"
const LOCAL_FUNC = "localhost:9000";

const API_URL = process.env.NODE_ENV === "development" ? `http://${LOCAL_FUNC}` : `https://${NETLIFY_FUNC}`;

const auth = new GoTrue({
  APIUrl: "https://farajaraftingchallenge.org/.netlify/identity",
  audience: "",
  setCookie: false
})

const handleBuildTeamMemberTags = (
  teamId,
  team,
  modifyMember,
  updateMember,
  isAdminMemberEditLoaderShowing,
  isModalDisabled,
  handleClick
) => {
  const currentTeam = team[teamId]
  return currentTeam.map(
    (item, index) =>
      item.length > 0 && (
        <div
          key={index}
          className="badge mr-3 mt-1"
          style={{ backgroundColor: "#E4419E", color: "#fff", padding: 8 }}
        >
          <span style={{ fontSize: 20 }}>{`${item[6]} ${item[7]}`}</span>
          <Popup
            trigger={
              <FontAwesomeIcon
                icon={faPen}
                className="ml-2"
                style={{ marginBottom: 2, cursor: "pointer" }}
              ></FontAwesomeIcon>
            }
            modal
            closeOnDocumentClick
            disabled={isModalDisabled}
          >
            <form name="teams" className="mt-3" method="POST">
              <h2
                style={{ textAlign: "center", color: "#000", marginBottom: 25 }}
              >
                Update {`${item[6]}'s`} Info
              </h2>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <label
                  style={{
                    flex: 3,
                    textAlign: "left",
                    marginTop: 5,
                    color: "#000",
                    fontSize: 17
                  }}
                >
                  <strong>First Name</strong>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="firstName"
                  value={item[6]}
                  onChange={e => {
                    modifyMember(item[0], item[14], 6, e.target.value)
                  }}
                  maxLength={30}
                  style={{ flex: 6 }}
                ></input>
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <label
                  style={{
                    flex: 3,
                    textAlign: "left",
                    marginTop: 5,
                    color: "#000",
                    fontSize: 17
                  }}
                >
                  <strong>Last Name</strong>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="lastName"
                  value={item[7]}
                  onChange={e => {
                    modifyMember(item[0], item[14], 7, e.target.value)
                  }}
                  maxLength={30}
                  style={{ flex: 6 }}
                ></input>
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <label
                  style={{
                    flex: 3,
                    textAlign: "left",
                    marginTop: 5,
                    color: "#000",
                    fontSize: 17
                  }}
                >
                  <strong>Email Address</strong>
                </label>
                <input
                  className="form-control"
                  type="email"
                  name="emailAddress"
                  value={item[8]}
                  onChange={e => {
                    modifyMember(item[0], item[14], 8, e.target.value)
                  }}
                  maxLength={30}
                  style={{ flex: 6 }}
                ></input>
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <label
                  style={{
                    flex: 3,
                    textAlign: "left",
                    marginTop: 5,
                    color: "#000",
                    fontSize: 17
                  }}
                >
                  <strong>Mobile Number</strong>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="mobileNumber"
                  value={item[9]}
                  onChange={e => {
                    modifyMember(item[0], item[14], 9, e.target.value)
                  }}
                  maxLength={30}
                  style={{ flex: 6 }}
                ></input>
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <label
                  style={{
                    flex: 3,
                    textAlign: "left",
                    marginTop: 5,
                    color: "#000",
                    fontSize: 17
                  }}
                >
                  <strong>Shirt Size</strong>
                </label>

                <div className="col text-center">
                  <label
                    style={{
                      textAlign: "left",
                      marginTop: 5,
                      color: "#000",
                      fontSize: 15
                    }}
                  >
                    S
                  </label>
                  <input
                    type="radio"
                    className="form-control"
                    placeholder=""
                    value={"S"}
                    checked={item[10] === "S"}
                    onChange={e => {
                      modifyMember(item[0], item[14], 10, e.target.value)
                    }}
                  />
                </div>

                <div className="col text-center">
                  <label
                    style={{
                      textAlign: "left",
                      marginTop: 5,
                      color: "#000",
                      fontSize: 15
                    }}
                  >
                    M
                  </label>
                  <input
                    type="radio"
                    className="form-control"
                    placeholder=""
                    value={"M"}
                    checked={item[10] === "M"}
                    onChange={e => {
                      modifyMember(item[0], item[14], 10, e.target.value)
                    }}
                  />
                </div>

                <div className="col text-center">
                  <label
                    style={{
                      textAlign: "left",
                      marginTop: 5,
                      color: "#000",
                      fontSize: 15
                    }}
                  >
                    L
                  </label>
                  <input
                    type="radio"
                    className="form-control"
                    placeholder=""
                    value={"L"}
                    checked={item[10] === "L"}
                    onChange={e => {
                      modifyMember(item[0], item[14], 10, e.target.value)
                    }}
                  />
                </div>

                <div className="col text-center">
                  <label
                    style={{
                      textAlign: "left",
                      marginTop: 5,
                      color: "#000",
                      fontSize: 15
                    }}
                  >
                    XL
                  </label>
                  <input
                    type="radio"
                    className="form-control"
                    placeholder=""
                    value={"XL"}
                    checked={item[10] === "XL"}
                    onChange={e => {
                      modifyMember(item[0], item[14], 10, e.target.value)
                    }}
                  />
                </div>

                <div className="col text-center">
                  <label
                    style={{
                      textAlign: "left",
                      marginTop: 5,
                      color: "#000",
                      fontSize: 15
                    }}
                  >
                    XXL
                  </label>
                  <input
                    type="radio"
                    className="form-control"
                    placeholder=""
                    value={"XXL"}
                    checked={item[10] === "XXL"}
                    onChange={e => {
                      modifyMember(item[0], item[14], 10, e.target.value)
                    }}
                  />
                </div>
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <label
                  style={{
                    flex: 3,
                    textAlign: "left",
                    marginTop: 5,
                    color: "#000",
                    fontSize: 17
                  }}
                >
                  <strong>Pickup Spot</strong>
                </label>
                <select
                  value={item[11]}
                  onChange={e => {
                    modifyMember(item[0], item[14], 11, e.target.value)
                  }}
                  style={{ flex: 6 }}
                >
                  <option style={{ fontSize: 15 }} value={"None"}>
                    None (I have my own transport)
                  </option>
                  <option style={{ fontSize: 15 }} value={"Faraja"}>
                    Faraja
                  </option>
                  <option style={{ fontSize: 15 }} value={"TRM"}>
                    TRM
                  </option>
                  <option style={{ fontSize: 15 }} value={"Ruiru"}>
                    Ruiru
                  </option>
                </select>
              </div>

              <div style={{ textAlign: "center" }} className="mt-2">
                <button
                  className="btn badge btn-sm mr-3"
                  style={{ fontSize: 20, background: "red" }}
                  onClick={event => {
                    event.preventDefault()
                    handleClick(event, true)
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn badge btn-sm"
                  style={{
                    fontSize: 20,
                    background: "mediumseagreen"
                  }}
                  onClick={event => {
                    event.preventDefault()
                    updateMember(item[0], item[14], currentTeam, event)
                  }}
                >
                  {isAdminMemberEditLoaderShowing ? "Updating" : "Update"}
                  {isAdminMemberEditLoaderShowing && (
                    <span className="ml-2">
                      <Loader color="#fff" size={28} />
                    </span>
                  )}
                </button>
              </div>
            </form>
          </Popup>
        </div>
      )
  )
}

const formatFetchedData = (
  team,
  key,
  teamList,
  deleter,
  sendmail,
  sendApprovedMail,
  sendDeclinedMail,
  approve,
  decline,
  modalState,
  handleClick,
  isLoaderShowing,
  isEmailLoaderShowing,
  isApprovalLoaderShowing,
  isDeclineLoaderShowing,
  modifyTeamInState,
  slots,
  updateGeneralTeamInfo,
  allTeamData,
  modifyMemberInState,
  updateSingleMemberInfo,
  isAdminGeneralEditLoaderShowing,
  isAdminMemberEditLoaderShowing
) => {
  return (
    <div className="col-12" key={key}>
      <div className=" row">
        <div className="col-12 col-md-12">
          <Popup
            trigger={
              <button
                title={`Approve/Decline "${team[1]}"`}
                className="btn btn-default admin_edit_button"
              >
                <Icon icon={ic_verified_user} />
              </button>
            }
            modal
            closeOnDocumentClick
            disabled={modalState}
          >
            <span className="edit_team_pop_up">
              {" "}
              <p>Please choose an action for {`${team[1]}`}</p>
              <div className="row">
                <div className={`col-4`}>
                  <button
                    type="button"
                    className="btn btn-success float-right"
                    style={{
                      background: "mediumseagreen",
                      cursor:
                        team[12].toLowerCase() !== "yes" ? "pointer" : "no-drop"
                    }}
                    onClick={event => {
                      if (team[12].toLowerCase() !== "yes") {
                        approve(team[0], event, team[4], team[5])
                        sendApprovedMail(
                          team[8],
                          `${team[6]} ${team[7]}`,
                          team[1],
                          team[4],
                          team[5],
                          team[5],
                          allTeamData[team[0]],
                          event
                        )
                      }
                    }}
                  >
                    {isApprovalLoaderShowing ? "Approving" : "Approve"}
                    {isApprovalLoaderShowing && (
                      <span className="ml-2">
                        <Loader color="#fff" size={28} />
                      </span>
                    )}
                  </button>
                </div>
                <div className={`col-4`}>
                  <button
                    type="button"
                    className="btn btn-danger float-right decline_btn"
                    style={{
                      cursor:
                        team[12].toLowerCase() !== "no" ? "pointer" : "no-drop"
                    }}
                    onClick={event => {
                      if (team[12].toLowerCase() !== "no") {
                        decline(team[0], event, team[4], team[5])
                        sendDeclinedMail(
                          team[8],
                          `${team[6]} ${team[7]}`,
                          team[1],
                          team[4],
                          team[5],
                          team[5],
                          teamList,
                          event
                        )
                      }
                    }}
                  >
                    {isDeclineLoaderShowing ? "Declining" : "Decline"}
                    {isDeclineLoaderShowing && (
                      <span className="ml-2">
                        <Loader color="#fff" size={28} />
                      </span>
                    )}
                  </button>
                </div>
                <div className={`col-4`}>
                  <button
                    type="button"
                    className="btn btn-secondary float-left"
                    onClick={event => {
                      event.preventDefault()
                      handleClick(event, true)
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </span>
          </Popup>

          {/* <Popup
            trigger={
              <button
                title={`Send email reminder to ${team[8]}`}
                className="btn btn-default admin_edit_button send_email"
              >
                <Icon icon={send} />
              </button>
            }
            modal
            closeOnDocumentClick
            disabled={modalState}
          >
            <span className="edit_team_pop_up">
              {" "}
              <p>
                Send itinerary email to {`${team[6]} ${team[7]}`} (
                <strong>{team[8]}</strong>)?
              </p>
              <div className="row">
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-danger float-right"
                    onClick={event => {
                      sendmail(
                        team[1],
                        team[8],
                        team[4],
                        team[5],
                        teamList,
                        event
                      )
                      // handleClick(event, true);
                    }}
                  >
                    {isEmailLoaderShowing ? "Sending" : "Send"}
                    {isEmailLoaderShowing && (
                      <span className="ml-2">
                        <Loader color="#fff" size={28} />
                      </span>
                    )}
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-secondary float-left"
                    onClick={event => {
                      event.preventDefault()
                      handleClick(event, true)
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </span>
          </Popup> */}

          <Popup
            trigger={
              <button
                title={`Delete team "${team[1]}"`}
                className="btn btn-default admin_edit_button"
              >
                <Icon icon={trash} />
              </button>
            }
            modal
            closeOnDocumentClick
            disabled={modalState}
          >
            <span className="edit_team_pop_up">
              {" "}
              <p>Confirm that you want to delete this team</p>
              <div className="row">
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-danger float-right"
                    onClick={event => {
                      deleter(team[0], team[4], team[5], event)
                    }}
                  >
                    {isLoaderShowing ? "Deleting" : "Delete"}
                    {isLoaderShowing && (
                      <span className="ml-2">
                        <Loader color="#fff" size={28} />
                      </span>
                    )}
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-secondary float-left"
                    onClick={event => {
                      event.preventDefault()
                      handleClick(event, true)
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </span>
          </Popup>

          <Popup
            trigger={
              <button
                title={`Edit team "${team[1]}"`}
                className="btn btn-default admin_edit_button"
              >
                <Icon icon={feather} />
              </button>
            }
            modal
            closeOnDocumentClick
            disabled={modalState}
          >
            <form name="teams" className="mt-3" method="POST">
              <h2
                style={{ textAlign: "center", color: "#000", marginBottom: 25 }}
              >
                Update {`${team[1]}'s`} Info
              </h2>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <label
                  style={{
                    flex: 3,
                    textAlign: "left",
                    marginTop: 5,
                    color: "#000",
                    fontSize: 17
                  }}
                >
                  <strong>Team Name</strong>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="teamName"
                  maxLength={30}
                  value={team[1]}
                  onChange={e => {
                    modifyTeamInState(team[0], 1, e.target.value)
                  }}
                  style={{ flex: 6 }}
                ></input>
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <label
                  style={{
                    flex: 3,
                    textAlign: "left",
                    marginTop: 5,
                    color: "#000",
                    fontSize: 17
                  }}
                >
                  <strong>Organisation</strong>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="organisation"
                  maxLength={30}
                  value={team[2]}
                  onChange={e => {
                    modifyTeamInState(team[0], 2, e.target.value)
                  }}
                  style={{ flex: 6 }}
                ></input>
              </div>

              <div
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <label
                  style={{
                    flex: 3,
                    textAlign: "left",
                    marginTop: 5,
                    color: "#000",
                    fontSize: 17
                  }}
                >
                  <strong>Target Amount</strong>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="targetAmount"
                  value={team[3]}
                  onChange={e => {
                    modifyTeamInState(team[0], 3, e.target.value)
                  }}
                  maxLength={30}
                  style={{ flex: 6 }}
                ></input>
              </div>

              <div className={"row"}>
                <div className={"col-12"}>
                  <label
                    style={{
                      flex: 3,
                      textAlign: "left",
                      marginTop: 5,
                      color: "#000",
                      fontSize: 17
                    }}
                  >
                    <strong>Rafting Day/Time</strong>
                  </label>
                </div>
              </div>
              {/* Select rafting section 1 */}
              <div className={"row m-0"} style={{ display: "none" }}>
                <div className={"col-12 col-md-12"}>
                  <div className={"row"}>
                    <div
                      className={`${styles.rafting_day} col-12 col-md-4 col-sm-12`}
                    >
                      <h3>7th June,
                        <br />
                        (Friday)
                      </h3>
                    </div>
                    <div
                      className={`${styles.rafting_time
                        } ${slots.friday < 1 &&
                        styles.disabled} ${team[4] === "Friday, 24th May" &&
                        team[5] === "Full Day" &&
                        styles.selected} col-6 col-lg-4 col-md-4 col-sm-6`}
                    >
                      <a
                        onClick={() => {
                          if (slots.friday > 0) {
                            modifyTeamInState(team[0], 4, "Friday, 24th May")
                            modifyTeamInState(team[0], 5, "Full Day")
                          }
                        }}
                      >
                        Full day
                      </a>
                    </div>
                  </div>

                </div>
              </div>

              {/* Slots for Friday Morning and Afternoon */}
              <div className={"row m-0"}>
                <div className={"col-12 col-md-12"}>
                  <div className={"row"}>
                    <div
                      className={`${styles.rafting_day} col-12 col-md-4 col-sm-12`}
                    >
                      <h3>7th June,
                        <br />
                        (Friday)
                      </h3>
                    </div>
                    <div
                      className={`${styles.rafting_time
                        } ${slots.fridayMorning < 1 &&
                        styles.disabled} ${team[4] === "Friday, 24th May" &&
                        team[5] === "Morning" &&
                        styles.selected} col-6 col-lg-4 col-md-4 col-sm-6`}
                    >
                      <a
                        onClick={() => {
                          if (slots.fridayMorning > 0) {
                            modifyTeamInState(team[0], 4, "Friday, 24th May")
                            modifyTeamInState(team[0], 5, "Morning")
                          }
                        }}
                      >
                        Morning
                      </a>
                    </div>
                    <div
                      className={`${styles.rafting_time
                        } ${slots.fridayAfternoon < 1 &&
                        styles.disabled} ${team[4] === "Friday, 24th May" &&
                        team[5] === "Afternoon" &&
                        styles.selected} col-6 col-lg-4 col-md-4 col-sm-6`}
                    >
                      <a
                        onClick={() => {
                          if (slots.fridayAfternoon > 0) {
                            modifyTeamInState(team[0], 4, "Friday, 24th May")
                            modifyTeamInState(team[0], 5, "Afternoon")
                          }
                        }}
                      >
                        Afternoon
                      </a>
                    </div>
                  </div>
                </div>
              </div>


              {/* Select rafting section 2 */}
              <div className={"row m-0"}>
                <div className={"col-12 col-md-12"}>


                  <div className={"row"}>
                    <div
                      className={`${styles.rafting_day} col-12 col-md-4 col-sm-12`}
                    >
                      <h3>8th June,
                        <br />
                        (Saturday)
                      </h3>
                    </div>
                    <div
                      className={`${styles.rafting_time
                        } ${slots.saturdayMorning < 1 &&
                        styles.disabled} ${team[4] === "Saturday, 25th May" &&
                        team[5] === "Morning" &&
                        styles.selected} col-6 col-lg-4 col-md-4 col-sm-6`}
                    >
                      <a
                        onClick={() => {
                          if (slots.saturdayMorning > 0) {
                            modifyTeamInState(team[0], 4, "Saturday, 25th May")
                            modifyTeamInState(team[0], 5, "Morning")
                          }
                        }}
                      >
                        Morning
                      </a>
                    </div>
                    <div
                      className={`${styles.rafting_time
                        } ${slots.saturdayAfternoon < 1 &&
                        styles.disabled} ${team[4] === "Saturday, 25th May" &&
                        team[5] === "Afternoon" &&
                        styles.selected} col-6 col-lg-4 col-md-4 col-sm-6`}
                    >
                      <a
                        onClick={() => {
                          if (slots.saturdayAfternoon > 0) {
                            modifyTeamInState(team[0], 4, "Saturday, 25th May")
                            modifyTeamInState(team[0], 5, "Afternoon")
                          }
                        }}
                      >
                        Afternoon
                      </a>
                    </div>
                  </div>

                </div>
              </div>

              {/* Select rafting section 3 */}
              <div className={"row m-0"} style={{ display: "none" }}>
                <div className={"col-12 col-md-12"}>
                  <div className={"row"}>
                    <div
                      className={`${styles.rafting_day} col-12 col-md-4 col-sm-12`}
                    >
                      <h3>26th May,
                        <br />
                        (Sunday)
                      </h3>
                    </div>
                    <div
                      className={`${styles.rafting_time
                        } ${slots.sunday < 1 &&
                        styles.disabled} ${team[4] === "Sunday, 26th May" &&
                        team[5] === "Full Day" &&
                        styles.selected} col-6 col-lg-4 col-md-4 col-sm-6`}
                    >
                      <a
                        onClick={() => {
                          if (slots.sunday > 0) {
                            modifyTeamInState(team[0], 4, "Sunday, 26th May")
                            modifyTeamInState(team[0], 5, "Full Day")
                          }
                        }}
                      >
                        Full day
                      </a>
                    </div>
                  </div>

                </div>
              </div>

              <br />

              <div style={{ textAlign: "center" }} className="mt-2">
                <button
                  className="btn badge btn-sm mr-3"
                  style={{ fontSize: 20, background: "red" }}
                  onClick={event => {
                    event.preventDefault()
                    handleClick(event, true)
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn badge btn-sm"
                  style={{
                    fontSize: 20,
                    background: "mediumseagreen"
                  }}
                  onClick={event => {
                    event.preventDefault()
                    updateGeneralTeamInfo(team[0], event)
                  }}
                >
                  {isAdminGeneralEditLoaderShowing ? "Updating" : "Update"}
                  {isAdminGeneralEditLoaderShowing && (
                    <span className="ml-2">
                      <Loader color="#fff" size={28} />
                    </span>
                  )}
                </button>
              </div>
            </form>
          </Popup>

          <h2>{team && team[1]}</h2>
        </div>
      </div>

      <div className=" row">
        <div className="col-12 col-md-3">
          <p>
            <strong>
              <Icon icon={ic_done_all} />
            </strong>{" "}
            <strong>Approval Status:</strong>
          </p>
        </div>
        <div className="col-12 col-md-9">
          <span
            className={`badge badge-${team &&
              (team[12].toLowerCase() === "no"
                ? "danger"
                : team[12].toLowerCase() === "yes"
                  ? "success"
                  : "warning")}`}
            style={{ fontSize: 17 }}
          >
            {team &&
              (team[12].toLowerCase() === "no"
                ? "Declined"
                : team[12].toLowerCase() === "yes"
                  ? "Approved"
                  : "Pending")}
          </span>
        </div>
      </div>

      <div className=" row">
        <div className="col-12 col-md-3">
          <p>
            <strong>
              <Icon icon={user} />
            </strong>{" "}
            <strong>Team Leader:</strong>
          </p>
        </div>
        <div className="col-12 col-md-9">
          <p>{team && `${team[6]} ${team[7]}`}</p>
        </div>
      </div>

      <div className=" row">
        <div className="col-12 col-md-3">
          <p>
            <strong>
              <Icon icon={office} />
            </strong>{" "}
            <strong>Organisation:</strong>
          </p>
        </div>
        <div className="col-12 col-md-9">
          <p>{team && team[2]}</p>
        </div>
      </div>

      <div className=" row">
        <div className="col-12 col-md-3">
          <p>
            <strong>
              <Icon icon={envelopeO} />
            </strong>{" "}
            <strong>Email Address:</strong>
          </p>
        </div>
        <div className="col-12 col-md-9">
          <p>{team && team[8]}</p>
        </div>
      </div>

      <div className=" row">
        <div className="col-12 col-md-3">
          <p>
            <strong>
              <Icon icon={calendar} />
            </strong>{" "}
            <strong>Rafting Day/Time:</strong>
          </p>
        </div>
        <div className="col-12 col-md-9">
          <p>{team && `${team[4]}, ${team[5]}`}</p>
        </div>
      </div>

      <div className=" row">
        <div className="col-12 col-md-3">
          <p>
            <strong>
              <Icon icon={money} />
            </strong>{" "}
            <strong>Target Amount:</strong>
          </p>
        </div>
        <div className="col-12 col-md-9">
          <p>
            {team && `Ksh ${team[3]}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
        </div>
      </div>

      <div className=" row">
        <div className="col-12 col-md-3">
          <p>
            <strong>
              <FontAwesomeIcon icon={faChild} />
            </strong>
            <strong> Total Members:</strong>
          </p>
        </div>
        <div className="col-12 col-md-9">
          <p>{teamList && teamList.length}</p>
        </div>
      </div>

      <div className="teamList row">
        <div className="col-12 col-md-3">
          <p>
            <strong>
              <Icon icon={users} />
            </strong>
            <strong> Member Names:</strong>
          </p>
        </div>
        <div className="col-12 col-md-9">
          {teamList && teamList.length > 0
            ? handleBuildTeamMemberTags(
              team[0],
              allTeamData,
              modifyMemberInState,
              updateSingleMemberInfo,
              isAdminMemberEditLoaderShowing,
              modalState,
              handleClick
            )
            : "No members in your team yet"}
        </div>
      </div>
      <hr style={{ color: "#000" }} />
    </div>
  )
}

class AdminPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      forms: null,
      members: null,
      fetched: false,
      isModalDisabled: false,
      slots: {
        friday: 20,
        fridayMorning: 12,
        fridayAfternoon: 12,
        saturdayMorning: 10,
        saturdayAfternoon: 10,
        sunday: 10
      },
      duplicateTeams: [],
      individualMembersPerTeam: [],
      originalForms: []
    }
  }
  async componentDidMount() {
    const user = auth.currentUser()
    this.setState({ user }, async () => {
      if (user) {
        await this.navigateUser(user)
      } else {
        navigate("/register-new/")
      }
    })
  }
  navigateUser = async (user) => {
    let shouldNavToAdmin = false
    Object.keys(user.app_metadata).forEach(item => {
      if (item === "roles") {
        user.app_metadata.roles.find(role => {
          if (role === "admin") {
            shouldNavToAdmin = true
            return
          }
        })
      }
    })
    if (shouldNavToAdmin) {
      //already in admin
      await this.getAllTeam(user.id)
      return
    } else {
      fetch(
        `${API_URL}/queryForTeam?teamId=${user.id}&shouldRetrieve=true`
      )
        .then(response => {
          if (response.ok) {
            response.json().then(data => {
              if (data.data[0][0] === user.id) navigate("/team/")
              else navigate("/createTeam/")
            })
          } else {
            response.json().then(error => {
              navigate("/createTeam/")
            })
          }
        })
        .catch(err => console.log(`Fetch error: ${err}`))
    }
  }
  prepareJustTeams = arr => {
    let idList = []
    let teams = arr.filter(row => {
      if (idList.indexOf(row[0]) === -1 && row.length > 0) {
        idList.push(row[0])
        return row
      }
    })
    return { teams: teams, ids: idList }
  }
  prepareJustMembers = (arr, ids) => {
    let teamAcc = ids.reduce((acc, id) => {
      if (!acc[id]) acc[id] = []
      return acc
    }, {})
    return arr.reduce((acc, row) => {
      row.length > 0 && teamAcc[row[0]].push(`${row[6]} ${row[7]}`)
      return acc
    }, teamAcc)
  }
  getAllTeam = async (id) => {
    let data = (await adminQueryForTeams(id)).slice(1);

    let teamData = this.prepareJustTeams(data)
    let { ids } = teamData
    let memberData = this.prepareJustMembers(data, ids)
    let teamsById = data?.reduce((acc, item, ind) => {
      if (!acc[item[0]]) {
        acc[item[0]] = [item]
        return acc
      } else {
        acc[item[0]] = [...acc[item[0]], item]
        return acc
      }
    }, {}) || {};

    this.setState(
      {
        forms: teamData.teams,
        originalForms: teamData.teams,
        members: memberData,
        duplicateTeams: teamsById,
        individualMembersPerTeam: teamsById
      },
      async () => {
        let slotData = await adminQueryForSlots();
        let friday = parseFloat(slotData[1][0]);
        let fridayMorning = parseFloat(slotData[1][1])
        let fridayAfternoon = parseFloat(slotData[1][2])
        let saturdayMorning = parseFloat(slotData[1][3])
        let saturdayAfternoon = parseFloat(slotData[1][4])
        let sunday = parseFloat(slotData[1][5]);
        let slots = { friday, saturdayMorning, fridayMorning, fridayAfternoon, saturdayAfternoon, sunday }
        this.setState({ slots })
      }
    )

    this.setState({ fetched: true })
  }

  handleClick = (e, flag) => {
    if (this?.node?.contains(e?.target) && !flag) {
      //click is inside modal
      if (this.state.isModalDisabled) this.setState({ isModalDisabled: false })
      return
    }
    //do whatever i.e. close modal
    if (!this.state.isModalDisabled) this.setState({ isModalDisabled: true })
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false)
  }

  chooseDayToUpdateSheetWith = (day, time) => {
    switch (day) {
      case "Friday, 24th May":
        if (time === "Morning") {
          return "Friday-Morning"
        } else {
          return "Friday-Afternoon"
        }
      case "Saturday, 25th May":
        if (time === "Morning") {
          return "Saturday-Morning"
        } else {
          return "Saturday-Afternoon"
        }
      case "Sunday, 26th May":
        return "Sunday-Full Day"
    }
  }

  deleteTeam = (id, day, time, event) => {
    this.props.toggleAdminDeleteTeamLoad(true)
    const newSubmissions = this.state.forms.filter(form => form[0] !== id)
    fetch(`${API_URL}/removeEntireTeam?teamId=${id}`)
      .then(response => {
        if (response.ok) {
          response.text().then(() => {
            this.setState({ forms: newSubmissions }, () => {
              fetch(
                `${API_URL}/updateSlots?day=${this.chooseDayToUpdateSheetWith(
                  day,
                  time
                )}&revert=true`
              )
                .then(response => {

                })
                .catch(err => console.log(err))
            })
            this.props.toggleAdminDeleteTeamLoad(false)
          })
        } else {
          console.log(
            "Delete row request failed (Step 1/2 failed)",
            response.status
          )
          this.props.toggleAdminDeleteTeamLoad(false)
          this.handleClick(event, true)
        }
      })
      .catch(err => {
        console.log("Delete row request failed (Step 1/2 failed)", err)
        this.props.toggleAdminDeleteTeamLoad(false)
      })
  }
  handleFormDataEncode = data =>
    Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  sendEmail = (team, email, day, time, event) => {
    this.props.toggleEmailSendingLoad(true);
    fetch(
      `${API_URL}/sendemail?${this.handleFormDataEncode({
        team,
        email,
        day,
        time
      })}`
    )
      .then(response => {
        this.props.toggleEmailSendingLoad(false)
        this.handleClick(event, true)

      })
      .catch(err => {
        console.log(err)
        this.props.toggleEmailSendingLoad(false)
        this.handleClick(event, true)
      })
  }
  sendApprovedEmail = (
    email,
    name,
    team,
    day,
    time,
    amount,
    members,
    event
  ) => {
    const teamMembers = members.map(item => `${item[6]} ${item[7]}`)
    fetch(
      `${API_URL}/sendApprovedEmail?${this.handleFormDataEncode({
        email,
        name,
        team,
        day,
        time,
        teamMembers
      })}`
    )
      .then(response => {
        this.handleClick(event, true)

      })
      .catch(err => {
        console.log(err)
        this.handleClick(event, true)
      })
  }
  sendDeclinedEmail = (
    email,
    name,
    team,
    day,
    time,
    amount,
    members,
    event
  ) => {
    fetch(
      `${API_URL}/sendDeclinedEmail?${this.handleFormDataEncode({
        email,
        name,
        team,
        day,
        time,
        amount,
        members
      })}`
    )
      .then(response => {
        this.props.toggleEmailSendingLoad(false)
        this.handleClick(event, true)

      })
      .catch(err => {
        console.log(err)
        this.handleClick(event, true)
      })
  }
  updateTeamStatus = async (id, flag, event, newState, oldState, day) => {
    fetch(
      `${API_URL}/updateTeamStatus?teamId=${id}&approved=${flag}`
    )
      .then(response => {
        if (response.ok) {
          response.text().then(() => {
            this.handleClick(event, true)
            this.props.toggleApprovalLoad(false)
            this.props.toggleDeclineLoad(false)
            this.setState({ forms: newState })
          })
        } else {
          this.props.toggleApprovalLoad(false)
          this.props.toggleDeclineLoad(false)
          this.handleClick(event, true)
          this.setState({ forms: oldState })
        }
      })
      .catch(err => {
        this.props.toggleApprovalLoad(false)
        this.props.toggleDeclineLoad(false)
        this.handleClick(event, true)
        this.setState({ forms: oldState })
      })
    fetch(
      `${API_URL}/updateSlots?day=${day}${flag ? "" : "&revert=true"
      }`
    )
      .then(response => {
      })
      .catch(err => console.log(err))
  }
  triggerApproval = (id, e, day, time) => {
    const newDay = day.split(",")[0]
    const concatDay = `${newDay}-${time}`
    this.props.toggleApprovalLoad(true)
    let teamsCopy = this.state.forms
    const newState = teamsCopy.map(team => {
      if (team[0] === id) team[12] = "Yes"
      return team
    })
    this.updateTeamStatus(id, true, e, newState, this.state.forms, concatDay)
  }

  triggerDecline = (id, e, day, time) => {
    const newDay = day.split(",")[0]
    const concatDay = `${newDay}-${time}`
    this.props.toggleDeclineLoad(true)
    let teamsCopy = this.state.forms
    const newState = teamsCopy.map(team => {
      if (team[0] === id) team[12] = "No"
      return team
    })
    this.updateTeamStatus(id, false, e, newState, this.state.forms, concatDay)
  }

  modifyTeamInState = (teamId, index, value) => {
    let newForms = this.state.forms.map(arr => {
      if (arr[0] === teamId) arr[index] = value
      return arr
    })
    this.setState({ forms: newForms })
  }

  modifyMemberInState = (teamId, memberId, index, value) => {
    const updatedMembers = this.state.individualMembersPerTeam[teamId].map(
      member => {
        if (member[14] === memberId) member[index] = value
        return member
      }
    )
    const newState = {
      ...this.state.individualMembersPerTeam,
      [teamId]: updatedMembers
    }
    this.setState({ individualMembersPerTeam: newState })
  }

  objectifyMemberWithNewData = (
    member,
    shared = {
      teamName: "",
      organisation: "",
      targetAmount: "",
      raftingDay: "",
      raftingTime: ""
    },
    flag = false
  ) => {
    //flag checks for general edit or single member edit
    return member.reduce((acc, item, ind) => {
      switch (ind) {
        case 0:
          acc["teamId"] = item
          return acc
        case 1:
          acc["teamName"] = flag ? shared.teamName : item
          return acc
        case 2:
          acc["organisation"] = flag ? shared.organisation : item
          return acc
        case 3:
          acc["targetAmount"] = flag ? shared.targetAmount : item
          return acc
        case 4:
          acc["raftingDay"] = flag ? shared.raftingDay : item
          return acc
        case 5:
          acc["raftingTime"] = flag ? shared.raftingTime : item
          return acc
        case 6:
          acc["firstName"] = item
          return acc
        case 7:
          acc["lastName"] = item
          return acc
        case 8:
          acc["emailAddress"] = item
          return acc
        case 9:
          acc["mobileNumber"] = item
          return acc
        case 10:
          acc["shirtSize"] = item
          return acc
        case 11:
          acc["transport"] = item
          return acc
        case 12:
          acc["approved"] = item
          return acc
        case 13:
          acc["isTeamLeader"] = item
          return acc
        case 14:
          acc["memberId"] = item
          return acc
        case 15:
          acc["createdAt"] = item
          return acc
        case 16:
          acc["editable"] = item
          return acc
        case 17:
          acc["teamEditable"] = item
          return acc
        default:
          return acc
      }
    }, {})
  }

  updateSingleMemberInfo = (teamId, memberId, teamToSave, e) => {
    this.props.toggleSingleMemberAdminEditLoader(true)
    const teamData = this.state.forms.filter(team => team[0] === teamId)[0]
    const sharedData = {
      teamName: teamData[1],
      organisation: teamData[2],
      raftingDay: teamData[4],
      raftingTime: teamData[5],
      targetAmount: teamData[3]
    }
    const updatedMemberArr = teamToSave.filter(
      member => member[14] === memberId
    )[0]
    const updatedMemberObj = this.objectifyMemberWithNewData(
      updatedMemberArr,
      sharedData,
      true
    )
    fetch(
      `${API_URL}/updateTeamMember?${this.handleFormDataEncode(
        updatedMemberObj
      )}`
    )
      .then(res => res.text())
      .then(res => {
        this.props.toggleSingleMemberAdminEditLoader(false)
        this.handleClick(e, true)
      })
      .catch(err => {
        console.log(err)
        this.props.toggleSingleMemberAdminEditLoader(false)
        this.handleClick(e, true)
      })
  }

  updateGeneralTeamInfo = (teamId, e) => {
    this.props.toggleAdminGeneralEditLoader(true)
    let oldDay = ""
    const teamData = this.state.forms.filter(team => team[0] === teamId)[0]
    const sharedData = {
      teamName: teamData[1],
      organisation: teamData[2],
      raftingDay: teamData[4],
      raftingTime: teamData[5],
      targetAmount: teamData[3]
    }
    const updatedTeam = this.state.duplicateTeams[teamId].map(
      (member, position) => {
        if (position === 1)
          oldDay = this.chooseDayToUpdateSheetWith(member[4], member[5])
        return this.objectifyMemberWithNewData(member, sharedData, true)
      }
    )
    let newDay = this.chooseDayToUpdateSheetWith(
      sharedData.raftingDay,
      sharedData.raftingTime
    )
    if (
      oldDay !== newDay &&
      updatedTeam.length > 0 &&
      updatedTeam[0].approved.toLowerCase() === "yes"
    ) {
      fetch(`${API_URL}/updateSlots?day=${oldDay}&revert=true`)
        .then(response => {
          let newDay = this.chooseDayToUpdateSheetWith(
            sharedData.raftingDay,
            sharedData.raftingTime
          )
          fetch(`${API_URL}/updateSlots?day=${newDay}`)
            .then(response => {
            })
            .catch(err => console.log(err))
          if (response.ok) {
          } else {
            console.log("Slots update failed", response.status)
          }
        })
        .catch(err => console.log(err))
    }

    updatedTeam.forEach(member => {
      fetch(
        `${API_URL}/updateTeamMember?${this.handleFormDataEncode(
          member
        )}`
      )
        .then(res => res.text())
        .then(res => {
          this.handleClick(e, true)
        })
        .catch(err => {
          console.log(err)
          this.handleClick(e, true)
        })
    })
    setTimeout(() => this.props.toggleAdminGeneralEditLoader(false), 1200)
  }

  chooseDayToUpdateSheetWith = (day, time) => {
    switch (day) {
      case "Friday, 24th May":
        if (time === "Morning") {
          return "Friday-Morning"
        } else {
          return "Friday-Afternoon"
        }
      case "Saturday, 25th May":
        if (time === "Morning") {
          return "Saturday-Morning"
        } else {
          return "Saturday-Afternoon"
        }
      case "Sunday, 26th May":
        return "Sunday-Full Day"
    }
  }

  retrieveTeams = () => {
    const { forms, members } = this.state
    if (forms !== null && forms !== undefined) {
      const submissions = forms.map((form, ind) => {
        return formatFetchedData(
          form,
          ind,
          members[form[0]],
          this.deleteTeam,
          this.sendEmail,
          this.sendApprovedEmail,
          this.sendDeclinedEmail,
          this.triggerApproval,
          this.triggerDecline,
          this.state.isModalDisabled,
          this.handleClick,
          this.props.isLoaderShowing,
          this.props.isEmailLoaderShowing,
          this.props.isApprovalLoaderShowing,
          this.props.isDeclineLoaderShowing,
          this.modifyTeamInState,
          this.state.slots,
          this.updateGeneralTeamInfo,
          this.state.individualMembersPerTeam,
          this.modifyMemberInState,
          this.updateSingleMemberInfo,
          this.props.isAdminGeneralEditLoaderShowing,
          this.props.isAdminMemberEditLoaderShowing
        )
      })
      return (
        <div className="teamLeadContent row">
          {submissions.length > 0 ? (
            submissions
          ) : (
            <h4>
              <strong>No teams are registered yet</strong>
            </h4>
          )}
        </div>
      )
    } else {
      return (
        <div className="teamLeadContent row">
          {
            <h4>
              <strong>No teams are registered yet</strong>
            </h4>
          }
        </div>
      )
    }
  }
  render() {
    const { forms } = this.state
    const siteTitle = get(this, "props.data.site.siteMetadata.title")
    const dashboard = get(this, "props.data.allContentfulBlogPost.edges")[0]
      .node
    if (forms) {
      return (
        <Layout>
          <Helmet title={`Admin Dashboard | ${siteTitle}`} />
          <PageHero hero={dashboard} />
          <div className="wrapper">
            <Container>
              <div className="profile_info mt-3" style={{ zIndex: 9 }}>
                <div
                  data-netlify-identity-menu
                  style={{ visibility: this.state.user ? "visible" : "hidden" }}
                ></div>

                <span
                  className="profile_pic"
                  style={{ visibility: this.state.user ? "visible" : "hidden" }}
                ></span>
              </div>
              <div className="team_lead_card" ref={node => (this.node = node)}>
                {this.state.fetched ? (
                  <React.Fragment>
                    <span
                      style={{
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        color: "#E4419E"
                      }}
                    >
                      Admin
                    </span>
                    {forms === null ||
                      forms === undefined ||
                      Object.keys(forms).length === 0 ? (
                      <React.Fragment></React.Fragment>
                    ) : (
                      <a
                        href={
                          "https://spreadsheets.google.com/feeds/download/spreadsheets/Export?key=1BFwQ8mp4NFLF-7EPGyRCZeBNBtBtXZyRXTOXMNF5Pyg&exportFormat=csv"
                        }
                        className="badge ml-4 mb-2"
                        style={{
                          backgroundColor: "#E4419E",
                          color: "#fff",
                          padding: 8,
                          cursor: "pointer"
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span style={{ fontSize: 15 }}>
                          Download Spreadsheet
                        </span>
                      </a>
                    )}
                    {this.retrieveTeams()}
                  </React.Fragment>
                ) : (
                  <Loader msg="Preparing your data" />
                )}
              </div>
            </Container>
          </div>
        </Layout>
      )
    } else {
      return <React.Fragment></React.Fragment>
    }
  }
}

const mapStateToProps = state => ({
  isLoaderShowing: state.isAdminDeleteTeamLoaderShowing,
  isEmailLoaderShowing: state.isEmailLoaderShowing,
  isApprovalLoaderShowing: state.isApprovalLoaderShowing,
  isDeclineLoaderShowing: state.isDeclineLoaderShowing,
  isAdminGeneralEditLoaderShowing: state.isAdminGeneralEditLoaderShowing,
  isAdminMemberEditLoaderShowing: state.isAdminMemberEditLoaderShowing
})

const mapDispatchToProps = () => {
  return {
    toggleAdminDeleteTeamLoad,
    toggleEmailSendingLoad,
    toggleApprovalLoad,
    toggleDeclineLoad,
    toggleAdminGeneralEditLoader,
    toggleSingleMemberAdminEditLoader
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(AdminPage)

export const pageQuery = graphql`
  query adminDashboardQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(
      filter: { tags: { eq: "Register" } }
      sort: { fields: [sort], order: DESC }
    ) {
      edges {
        node {
          title
          slug
          heroImage {
            sizes(maxWidth: 1024, resizingBehavior: SCALE) {
              ...GatsbyContentfulSizes_withWebp
            }
          }

          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
