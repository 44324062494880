import axios from "axios";

const NETLIFY_FUNC = "farajaraftingchallenge.org/.netlify/functions"
const LOCAL_FUNC = "localhost:9000"

export async function adminQueryForTeams(userId){
    let apiUrl = `https://${NETLIFY_FUNC}/adminQueryTeams`;

    try {
        let {data: {data: teamData}} = await axios.get(apiUrl);

        return teamData;
    }catch(e){
        return []
    }

}

export async function adminQueryForSlots(){
    let apiUrl = `https://${NETLIFY_FUNC}/queryForSlots`;

    try {
        let {data: {data: slotData}} = await axios.get(apiUrl);

        return slotData
    }catch(e){
        return []
    }
}